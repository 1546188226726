<template>
	<div class="trusted">
		<div class="title">
			{{ $t('trust.trusted_by') }}
		</div>
		<div v-for="i,index in companylist" :key="'companyPage'+index">
			<div v-if="trusedPage==index+1">
				<div v-for="y,indexa in i" :key="'companyLine'+indexa" class="trans">
					<div class="picsR">
						<div v-for="x in y" :key="'company'+x.company_name" class="pic">
							<!-- <img :src="x.company_logo" style="width:100%"/> -->
							<img :src="x.company_logo" v-if="x.company_name!='Oxbridge'" style="width:100%" />
							<img :src="x.company_logo" v-else style="height:100%" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="dogs">
			<div v-for="i in allPage" :key="i">
				<span v-if="i==trusedPage" class="dogBlue" @click="change(i)">
				</span>
				<span v-else class="dogWhite" @click="change(i)">
				</span>
			</div>
		</div> -->
	</div>
</template>
<script>
	export default ({
		data() {
			return {
				trusedPage: 1,
				allPage: 2, // 20//10
				timer: '',
				companylist:[[[
					{
						company_name: "Mcgrath",
						company_logo: require('@/assets/imgs/pc/trustBy/Mcgrath.png')
					}, {
						company_name: "Stone",
						company_logo: require('@/assets/imgs/pc/trustBy/stone.png')
					}, {
						company_name: "Crown",
						company_logo: require('@/assets/imgs/pc/trustBy/crowngroup.png')
					}, {
						company_name: "Oneagency",
						company_logo: require('@/assets/imgs/pc/trustBy/oneagency.png')
					}, 
					],
					[{
						company_name: "Coronation",
						company_logo: require('@/assets/imgs/pc/trustBy/Coronation.png')
					}, {
						company_name: "Landmark",
						company_logo: require('@/assets/imgs/pc/trustBy/landmark.png')
					},{
						company_name: "SquareYards",
						company_logo: require('@/assets/imgs/pc/trustBy/squareyard.png')
					}, {
						company_name: "QuarterAcre",
						company_logo: require('@/assets/imgs/pc/trustBy/QuarterAcre.png')
					}],
					[{
						company_name: "Resimarket",
						company_logo: require('@/assets/imgs/pc/trustBy/Resimarket.png')
					}, {
						company_name: "Oxb",
						company_logo: require('@/assets/imgs/pc/trustBy/oxb.png')
					}, {
						company_name: "SH",
						company_logo: require('@/assets/imgs/pc/trustBy/SH.png')
					}, {
						company_name: "Greenland",
						company_logo: require('@/assets/imgs/pc/trustBy/greenland.png')
					}]],
				[[{
						company_name: "OzyHomes",
						company_logo: require('@/assets/imgs/pc/trustBy/ozyHomes.png')
					}, {
						company_name: "PietyGroup",
						company_logo: require('@/assets/imgs/pc/trustBy/pietyGroup.png')
					},{
						company_name: "LivePropertyMarketing",
						company_logo: require('@/assets/imgs/pc/trustBy/LivePropertyMarketing.png')
					}, {
						company_name: "Ventus",
						company_logo: require('@/assets/imgs/pc/trustBy/ventus.png')
					},
					],
				[{
						company_name: "Aland",
						company_logo: require('@/assets/imgs/pc/trustBy/Aland.png')
					},{
						company_name: "Tallpopie",
						company_logo: require('@/assets/imgs/pc/trustBy/tallpopie.png')
					}, {
						company_name: "Surplus",
						company_logo: require('@/assets/imgs/pc/trustBy/surplus.png')
						
					}, {
						company_name: "Libra",
						company_logo: require('@/assets/imgs/pc/trustBy/Libra.png')
					}, ],
					[{
						company_name: "Idream",
						company_logo: require('@/assets/imgs/pc/trustBy/idream.png')
					},{
						company_name: "Linic",
						company_logo: require('@/assets/imgs/pc/trustBy/linic.png')
					},{
						company_name: "KmacGroup",
						company_logo: require('@/assets/imgs/pc/trustBy/kmacGroup.png')
					},{
						company_name: "Edifice",
						company_logo: require('@/assets/imgs/pc/trustBy/edifice.png')
					}]]],
			};
		},
		created() {
			this.auto()
		},
		methods: {
			change(i) {
				this.trusedPage = i;
			},
			auto() {
				this.timer = setInterval(() => {
					if (this.trusedPage == 1) {
						this.trusedPage = 2
					} else {
						this.trusedPage = 1
					}
				}, 5000);
			}
		},
		beforeDestroy() {
			clearInterval(this.timer);
		}
	})
</script>
<style lang="scss" scoped>
	.trusted {
		position: relative;
		width: 100%;
		height:130px;
		.title {
			margin: auto;
			flex-grow: 0;
			letter-spacing: normal;
			white-space: nowrap;
			width: fit-content;
			height: 18px;
			font-family: 'Poppins';
			font-style: normal;
			font-size: 12px;
			line-height: 18px;
			color: #A9AEB8;
			margin-bottom: 20px;
		}

		.picsR {
			width: 322px;
			margin: auto;
			margin-top: 10px;
			display: flex;
			justify-content: space-between;
			

			.pic {
				width: 64px;
				height: 24px;
				margin: 0 2.5px 0 2.5px;
				align-items: center
			}
		}

		.dogs {
			position:absolute;
			  bottom: 10px;
			  right: 45%;
			// margin-top: 20px;
			// margin-left: 680px;
			width: 100%;
			// height: 10px;
			flex-grow: 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-end;
			gap: 10px;
			// margin: auto;
			// margin-bottom: 39px;
			// width: 22px;
			// height: 6px;
			// flex-grow: 0;
			// display: flex;
			// flex-direction: row;
			// // justify-content: flex-end;
			// // align-items: flex-end;
			// gap: 10px;

			.dogBlue {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #1890ff;
				display: inline-block;
			}

			.dogWhite {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #d8d8d8;
				display: inline-block;
			}
		}
	}

	.trans{
		// animation-name: fadeIn;
		// animation: ease-in;
		// animation-duration: 10s;
		animation: fadeIn 5s infinite ease-in-out;
		// animation-play-state: 
		// animation-iteration-count: infinite;
	}

	@keyframes fadeIn {
    0% {
      opacity: 0;
      // transform: translateX(-100px);
    }

    30% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      // transform: translate(0);
    }
  }
</style>
